import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/store/hooks';

import { selectClinic, setAvailableClinic } from 'lib/store/slices/clinics';
import { login } from 'lib/store/slices/users';
import Button from 'react-components/dist/components/Button';

import { forceHideSidebar, toggleSidebar } from 'lib/store/slices/components';
import clinics from 'lib/constants/api/clinics';
import APIUsers from 'lib/constants/api/users';

import { useNavigate } from 'react-router-dom';
import Spinner from 'react-components/dist/components/Spinner';
import ReactGA from 'react-ga4';

type Props = { resource: any };

function ListClinics({ resource }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const users = useAppSelector((state) => state.users);

  const [isLoading, setLoading] = useState<string | null>(null);

  const listClinics = resource?.read();

  useEffect(() => {
    dispatch(setAvailableClinic(listClinics?.data?.data ?? []));
  }, [dispatch, listClinics]);

  const fnSelectClinic = useCallback(
    async (item: { [key: string]: string }) => {
      try {
        setLoading(item._id);
        const response = await clinics.select(item._id);

        const settings = await clinics.getSettings(item._id);

        dispatch(toggleSidebar(false));

        dispatch(
          selectClinic({
            ...response.data.clinicDetails,
            poli: response.data.poli,
            doctor: response.data.doctor,
            staff: response.data.staff,
            iks: response.data.iks,
            nurse: response.data.nurse,
            settings: settings.data?.settings || {},
          })
        );

        const loginPayload: any = {
          token: response.data.user.token,
          tokenRefresh: response.data.user.tokenRefresh,
          profile: { ...users.profile },
        };

        if (users?.profile?.entity !== 'owner') {
          const resAuthority = await APIUsers.getAuthorityByUserIdAndClinicId(
            users?.profile?.idUser,
            item._id
          );
          loginPayload.profile.authority = resAuthority.data.authority;
        }

        dispatch(login(loginPayload));
        dispatch(forceHideSidebar(false));

        ReactGA.event({
          category: 'mainapp',
          action: 'enter_faskes',
          label: `${users.profile?.fullName} masuk ke ${response.data.clinicDetails.name}`, // optional
        });

        navigate(`/clinics/${item._id}`, { replace: true });
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigate, users.profile]
  );

  if (!listClinics) return null;

  const { data } = listClinics.data;

  return (
    <div className="px-4 flex flex-col gap-y-4 my-16">
      <div className="flex flex-col">
        <h2
          className="text-sm text-gray-600 dark:text-gray-400"
          data-uid-testing="page-faskes-selection"
        >
          Faskes Anda
        </h2>
        <span className="text-gray-800 dark:text-gray-200">
          Klik tombol <strong>Kelola Faskes</strong> untuk memulai
        </span>
      </div>
      <div className="flex flex-wrap gap-y-6 -mx-4">
        {data.map((item: { [key: string]: string }) => {
          return (
            <div className="w-full md:w-6/12 xl:w-4/12 px-4" key={item._id}>
              <div
                className="relative flex cursor-pointer h-full flex-col gap-x-6 rounded-lg bg-gray-50 p-6 text-gray-800 shadow-lg dark:bg-gray-800 dark:text-gray-200"
                data-uid-testing={`faskes-card-${item._id}`}
              >
                <div className="mb-4 h-20 w-20">
                  <img src={item.logo} alt={item.name} />
                </div>

                <h6 className="">{item.name}</h6>
                <span className="mb-4 text-xs">
                  {item.phoneNumber} / {item.fullAddress} - {item.provinsi} - {item.kabupaten} -{' '}
                  {item.kecamatan}
                </span>
                <div className="">
                  <Button ripple className="px-4" state="primary">
                    <button>{item.status === 'active' ? 'Kelola Faskes' : 'Hubungi Admin'}</button>
                  </Button>
                </div>

                {isLoading === item._id && (
                  <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-white/60 dark:bg-white/10">
                    <Spinner size="lg" />
                  </div>
                )}

                {item.status === 'active' ? (
                  <span
                    className="absolute inset-0 z-10"
                    onClick={() => fnSelectClinic(item)}
                  ></span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ListClinics;
