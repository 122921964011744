import { ReactComponent as Logo } from 'assets/images/logo-main.svg';
import { ReactComponent as Satusehat } from 'assets/images/satusehat.svg';
import { ReactComponent as LogoMejakasir } from 'assets/images/logo-mejakasir.svg';

import Button from 'react-components/dist/components/Button';
import Form from 'react-components/dist/components/Form';
import { useAppDispatch } from 'lib/store/hooks';

import { login } from 'lib/store/slices/users';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { setAuthorizationHeader } from 'lib/fetch/axios';

import clinics from 'lib/constants/api/clinics';
import auth from 'lib/constants/api/auth';
import users from 'lib/constants/api/users';
import { forceHideSidebar, resetAllForms, toggleSidebar } from 'lib/store/slices/components';
import { selectClinic } from 'lib/store/slices/clinics';
import Spinner from 'react-components/dist/components/Spinner';
import { toast } from 'react-toastify';
import useLocalStorage from 'lib/helpers/useLocalstorage';
import Input from 'react-components/dist/components/Input';
import { Eye, EyeClosed } from '@phosphor-icons/react';
import packageJson from '../../../package.json';
import ReactGA from 'react-ga4';
// import useWhatsapp from "lib/helpers/useWhatsapp";
import Banner from './Banner';
import decodeJWT from 'lib/helpers/jwt-decode';
import APIUsers from 'lib/constants/api/users';

interface ILoginProps {}

interface CustomizedState {
  from: {
    pathname: string;
  };
}

const entity: { [key: string]: string } = {
  owner: 'owners',
  staff: 'staffs',
  doctor: 'doctors',
  nurse: 'nurses',
};

export default function Login(props: ILoginProps) {
  useLayoutEffect(() => {
    document.title = 'Login | Mejadokter';
    ReactGA.send({
      hitType: 'pageview',
      page: '/login',
      title: 'Login | Mejadokter',
    });
  }, []);

  const [welcomeUser, setWelcomeUser] = useState({
    name: '',
    email: '',
  });

  const [seePassword, seePasswordSet] = useState(false);

  const [inlineNotifications, inlineNotificationsSet] = useLocalStorage<string[]>(
    'inlineNotifications',
    []
  );

  const [isFormLoading, setLoading] = useState(false);

  const [isLoadingRoot, isLoadingRootSet] = useState(false);

  // const whatsapp = useWhatsapp();
  // const whatsappCS = `${whatsapp}?phone=6285174349193&text=Hi%20Yein,%20Saya%20tertarik%20untuk%20mencoba%20fitur%20yang%20ditawarkan%20mejadokter`;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();

  const params = new URL(window.location.href).searchParams;
  let qq = window.location.href.split('&path=')[1];
  let queryParamsRedirect = '';
  if (qq) queryParamsRedirect = qq.split('?')[1];

  function fnChangeUser() {
    setWelcomeUser({ name: '', email: '' });
  }

  async function fnLogin(payload: any) {
    if (payload.email === '') {
      alert('email tidak boleh kosong');
      return;
    }

    if (payload.password === '') {
      alert('password tidak boleh kosong');
      return;
    }

    try {
      setLoading(true);
      const responseAuth = await auth.login(payload);
      const { user } = responseAuth.data;
      setAuthorizationHeader(user.token);

      const responseProfile = await users.getDetailsByEntity(entity[user.entity]);

      const profile = responseProfile?.data ?? {};

      dispatch(
        login({
          token: user.token,
          tokenRefresh: user.tokenRefresh,
          profile: {
            // authority: user.authority,
            email: user.email,
            entity: user.entity,
            idUser: user._id,
            idProfile: profile._id,
            gender: profile.gender,
            dateOfBirth: profile.dateOfBirth,
            fullAddress: profile.fullAddress,
            fullName: profile.fullName,
            idCard: profile.idCard,
            ijinPraktek: profile.ijinPraktek,
            phoneNumber: profile.phoneNumber,
            statusPernikahan: profile.statusPernikahan,
            createdAt: profile.createdAt,
            updatedAt: profile.updatedAt,
            metaData: profile.metaData,
            isBidan: profile.isBidan,
            isApoteker: profile.isApoteker,
            isApotekerAuthorized: profile.isApotekerAuthorized,
          },
        })
      );
      const from = state?.from?.pathname || '/clinics';

      if (!inlineNotifications.includes('reset-all-forms')) {
        dispatch(resetAllForms());
        inlineNotificationsSet((prev) => [...prev, 'reset-all-forms']);
      }

      navigate(from, { replace: true });
    } catch (error: any) {
      setLoading(false);
      toast.warning(error?.isAxiosError ? error?.response?.data?.errors.global : error?.message);
    }
  }

  useLayoutEffect(() => {
    if (welcomeUser.email === '') document.getElementById('email')?.focus();
    else document.getElementById('password')?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginRoot = useCallback(
    async (data: { token?: string; tokenRefresh?: string; clinic?: string; path?: string }) => {
      if (!isLoadingRoot) {
        try {
          if (!data.token) throw new Error('Token tidak valid');

          isLoadingRootSet(true);

          const authenticated = decodeJWT(data.token);

          setAuthorizationHeader(data.token);

          const responseProfile = await users.getDetailsByEntity(entity[authenticated.entity]);

          const loginPayload: any = {
            token: data.token,
            tokenRefresh: data.tokenRefresh,
            isAdminMejadokter: !!authenticated.isAdminMejadokter,
            profile: {
              email: authenticated.email,
              entity: authenticated.entity,
              idUser: responseProfile.data.idUser,
              idProfile: responseProfile.data._id,
              dateOfBirth: responseProfile.data.dateOfBirth,
              fullAddress: responseProfile.data.fullAddress,
              fullName: responseProfile.data.fullName,
              idCard: responseProfile.data.idCard,
              phoneNumber: responseProfile.data.phoneNumber,
              statusPernikahan: responseProfile.data.statusPernikahan,
              createdAt: responseProfile.data.createdAt,
              updatedAt: responseProfile.data.updatedAt,
              isBidan: responseProfile.data.isBidan,
              isApoteker: responseProfile.data.isApoteker,
              isApotekerAuthorized: responseProfile.data.isApotekerAuthorized,
            },
          };

          if (typeof data.clinic === 'string') {
            const getClinic = await clinics.select(data.clinic);

            loginPayload.token = getClinic.data.user.token;
            loginPayload.tokenRefresh = getClinic.data.user.tokenRefresh;

            const settings = await clinics.getSettings(getClinic.data.clinicDetails._id);

            dispatch(toggleSidebar(false));
            dispatch(
              selectClinic({
                ...getClinic.data.clinicDetails,
                poli: getClinic.data.poli,
                doctor: getClinic.data.doctor,
                iks: getClinic.data.iks,
                nurse: getClinic.data.nurse,
                staff: getClinic.data.staff,
                settings: settings.data?.settings || {},
              })
            );

            if (authenticated.entity !== 'owner') {
              const resAuthority = await APIUsers.getAuthorityByUserIdAndClinicId(
                authenticated._id || getClinic.data.user._id,
                data.clinic
              );
              loginPayload.profile.authority = resAuthority.data.authority;
            }

            dispatch(forceHideSidebar(false));

            if (data.path?.includes('?')) data.path = data.path.split('?')[0];
            if (data.path?.includes(':clinic'))
              data.path = data.path.replace(':clinic', data.clinic);
            if (data.path?.includes(':patient'))
              data.path = data.path.replace(':patient', params.get('patient') || '');
            if (data.path?.includes(':service'))
              data.path = data.path.replace(':service', params.get('service') || '');

            if (queryParamsRedirect !== '') data.path += `?${queryParamsRedirect}`;
          }

          dispatch(login(loginPayload));

          navigate(data.path || '/clinics');
        } catch (error) {
          console.log(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigate, params, isLoadingRoot]
  );

  useEffect(() => {
    if (params.has('token') && params.get('root') === '1') {
      loginRoot({
        token: params.get('token')!,
        tokenRefresh: params.get('tokenRefresh')!,
        clinic: params.get('clinic')!,
        path: params.get('path')!,
      });
    } else if (params.has('reason') && params.get('reason') === 'session-expired') {
      toast.warning('Sesi Anda telah berakhir, silahkan login kembali', {
        toastId: 'sessionExpired',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="min-h-screen bg-gray-50 dark:bg-gray-950">
      <div className="flex items-start justify-center">
        <Banner />
        <div className="w-full flex-1 flex min-h-screen flex-col items-center justify-start">
          <div className="mt-16 relative">
            <span className="block dark:hidden">
              <Logo width={220} className="logo-yellow" />
            </span>
            <span className="hidden dark:block">
              <Logo width={220} className="logo-pure-white" />
            </span>
            <span className="pointer-events-none text-xxs text-gray-900 dark:text-gray-200 absolute transform -translate-y-2 right-2">
              v{packageJson.version}
            </span>
          </div>
          <div className="mt-8">
            <div className="relative z-10 mx-4 flex w-auto flex-col rounded-sm bg-white dark:bg-gray-900 p-8 shadow-xl dark:shadow-gray-950 md:w-96">
              {params.has('token') && params.get('root') === '1' && isLoadingRoot ? (
                <div className="flex h-40 items-center justify-center dark:text-gray-200">
                  <Spinner size="sm" />
                  <span className="mb-4 ml-3 md:mb-0">Mempersiapkan akun faskes Anda</span>
                </div>
              ) : (
                <>
                  <h3
                    className="text-center text-sm dark:text-gray-400"
                    data-uid-testing="login-message"
                  >
                    Selamat datang{' '}
                    {(welcomeUser.name && welcomeUser.name.split(' ')[0]) || 'mitra mejadokter'}
                    {welcomeUser.email !== '' && (
                      <span>
                        ,{' '}
                        <span
                          className="cursor-pointer text-blue-600 underline"
                          onClick={fnChangeUser}
                        >
                          ganti user
                        </span>
                      </span>
                    )}
                  </h3>
                  <div className="mt-6">
                    <Form onSubmit={fnLogin}>
                      <div className="flex flex-col gap-y-4">
                        <Input
                          isRequired
                          id="email"
                          name="email"
                          type="email"
                          label="Username/email"
                          defaultValue=""
                          className="px-4"
                        />

                        <Input
                          isRequired
                          id="password"
                          name="password"
                          type={seePassword ? 'text' : 'password'}
                          label="Password"
                          defaultValue=""
                          className="px-4"
                          wrapperClassName="relative"
                          addOn={
                            <span className="absolute top-0 right-0 h-full aspect-square">
                              <button
                                type="button"
                                className="w-full h-full flex items-center justify-center dark:text-gray-200"
                                onClick={() => seePasswordSet((prev) => !prev)}
                              >
                                {seePassword ? <Eye size={18} /> : <EyeClosed size={18} />}
                              </button>
                            </span>
                          }
                        />

                        <Button
                          isLoading={isFormLoading}
                          ripple
                          className="w-full justify-center rounded"
                        >
                          <button data-uid-testing="submit">Masuk</button>
                        </Button>
                      </div>
                    </Form>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-4 justify-center my-8 text-center">
            <h6 className="text-sm text-gray-900 dark:text-gray-200">Terintegrasi dengan</h6>
            <div className="flex gap-x-4 h-10">
              <span className="flex items-center gap-x-2">
                <Satusehat className="logo-satusehat h-10" />
                <span className="font-bold text-xl text-gray-900 dark:text-gray-200">
                  SATUSEHAT
                </span>
              </span>
              {/* <img
              className="object-contain w-full h-full"
              src="/assets/images/logo-satset.webp"
              alt="logo satu sehat"
            /> */}
              <img
                className="object-contain h-full"
                src="/assets/images/logo-bpjs-kesehatan.webp"
                alt="logo bpjs"
              />
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-4 justify-center my-8 text-center">
            <h6 className="text-sm text-gray-900 dark:text-gray-200">Aplikasi lainnya</h6>
            <div className="flex gap-x-4 h-10">
              <span className="flex items-center gap-x-2 relative">
                <LogoMejakasir className="logo-mejakasir h-10 w-10 rounded" />
                <span className="font-bold text-xl text-gray-900 dark:text-gray-200">
                  mejakasir
                </span>
                <a
                  href="https://mejakasir.com?ref=mejadokter"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="absolute inset-0"
                >
                  {/* <!-- mejadokter link --> */}
                </a>
              </span>
            </div>
          </div>

          <div className="relative flex-col gap-y-4 items-center lg:flex-row z-10 my-8 flex text-sm text-gray-600 dark:text-gray-400">
            <a
              href="https://mejadokter.com/terms-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Syarat & Ketentuan
            </a>

            <span className="px-4 hidden lg:inline-block">|</span>

            <a
              href="https://mejadokter.com/privacy-policies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kebijakan Privasi
            </a>
          </div>

          {/* <div className="relative md:absolute mt-8 bottom-0 left-0 right-0 flex justify-center">
          <img src="/assets/images/login-bg.png" alt="Login Mejadokter" />
        </div> */}
        </div>
      </div>
    </main>
  );
}
