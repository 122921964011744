import { ArrowSquareOut } from '@phosphor-icons/react';
import { useAppSelector } from 'lib/store/hooks';
import { TPatientPager } from 'lib/store/slices/clinics';
import Button from 'react-components/dist/components/Button';

type Props = { resource: any; reload: () => void };

function ListPatientPagers({ resource }: Props) {
  const users = useAppSelector((state) => state.users);
  const { selected } = useAppSelector((state) => state.clinics);
  const data = resource.read();

  function openPatientPager(item: TPatientPager, isDev: boolean) {
    const encodedToken = btoa(
      JSON.stringify({
        clinicId: selected?._id,
        token: users.token,
        tokenRefresh: users.tokenRefresh,
      })
    );

    const host = isDev ? 'http://localhost:9003' : process.env.REACT_APP_DOCUMENT_URL;

    const link = `${host}/patient-pagers/${selected?.slug}/${item._id}?t=${encodedToken}`;

    window.open(link, `pemanggil-antrean-mejadokter-${item._id}`);
  }

  if (data.data.length === 0)
    return <span className="text-gray-900 dark:text-gray-200">No Patient Pager</span>;

  return data.data.map((item: TPatientPager) => {
    return (
      <div
        key={item._id}
        className="relative flex rounded border border-gray-200 aspect-square dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 p-4 w-full items-center justify-center transition-all duration-300"
      >
        <span className="text-gray-900 dark:text-gray-200">{item.name}</span>
        <Button className="!p-3 !absolute top-4 right-4" ripple state="secondary">
          <button type="button" onClick={() => openPatientPager(item, false)}>
            <ArrowSquareOut size={22} />
          </button>
        </Button>
        {(process.env.NODE_ENV === 'development' || users.isAdminMejadokter) && (
          <span
            className="absolute top-14 right-4 text-xs text-gray-600 dark:text-gray-400 cursor-pointer"
            onClick={() => openPatientPager(item, true)}
          >
            DEV
          </span>
        )}
        <span className="absolute bottom-3 left-3 text-xs text-gray-600 dark:text-gray-400">
          v2
        </span>
        <span className="absolute bottom-3 right-3 text-xs text-gray-600 dark:text-gray-400">
          {item.template}
        </span>
      </div>
    );
  });
}

export default ListPatientPagers;
