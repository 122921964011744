import axios from 'lib/fetch/axios';

import getSelectedIdClinic from 'lib/helpers/getSelectedIdClinic';
import { TClinicSettings } from 'lib/store/slices/clinics';
import { TPayloadMetaDataCrm } from 'pages/Integration/Crm/Setup';
import { TPayloadPost, TPostType } from 'pages/Integration/LandingPage/Posts';
import { TPayloadMetaDataLandingPage } from 'pages/Integration/LandingPage/Setup';

function list() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/clinics`,
  });
}

function getBalance() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/balances`,
  });
}

function getBalanceLogs() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/balances-logs`,
  });
}

function select(id: string) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL2}/auth/web-app/token-for-spesific-clinic/${id}`,
  });
}

function getHakDanKewajibanPasien() {
  return axios({
    method: 'get',
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/hak-kewajiban-pasien`,
  });
}

function update(data: { [key: string]: any }) {
  return axios({
    method: 'put',
    data,
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/clinics`,
  });
}

function getKioskPage() {
  return axios({
    method: 'post',
    data: {},
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/documents/kiosk`,
  });
}

/* START LANDING PAGE */
function getLandingPage() {
  return axios({
    method: 'get',
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page`,
  });
}

function activateLandingPage() {
  return axios({
    method: 'put',
    data: {},
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page/activation`,
  });
}

function updateLandingPage(data: TPayloadMetaDataLandingPage) {
  return axios({
    method: 'put',
    data,
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page/update`,
  });
}

function getLandingPagePost(type: TPostType) {
  return axios({
    method: 'get',
    params: {
      type,
    },
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page/posts`,
  });
}

function createLandingPagePost(data: TPayloadPost) {
  return axios({
    method: 'post',
    data,
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page/posts`,
  });
}

function updateLandingPagePost(id: string, data: TPayloadPost) {
  return axios({
    method: 'put',
    data,
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page/posts/${id}`,
  });
}

function deleteLandingPagePost(id: string) {
  return axios({
    method: 'delete',
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/landing-page/posts/${id}`,
  });
}
/* END LANDING PAGE */

/* BEGIN Satu sehat */

function getSatuSehat() {
  return axios({
    method: 'get',
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/clinics/${getSelectedIdClinic()}/satu-sehat-credentials`,
  });
}

function updateSatuSehat(data: {
  idClinic: string;
  organizationId: string;
  clientKey: string;
  secretKey: string;
}) {
  return axios({
    method: 'put',
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/clinics/${getSelectedIdClinic()}/satu-sehat-credentials`,
    data,
  });
}

/* END Satu sehat */

/* BEGIN CRM */
function getCrm() {
  return axios({
    method: 'get',
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/crm`,
  });
}

function activateCrm() {
  return axios({
    method: 'put',
    data: {},
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/crm/activation`,
  });
}

function updateCrm(data: TPayloadMetaDataCrm) {
  return axios({
    method: 'put',
    data,
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/integrations/crm/update`,
  });
}

function getSettings(idClinic?: string) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL}/api/v2/clinics/${
      !!idClinic ? idClinic : getSelectedIdClinic()
    }/settings`,
  });
}

function updateSettings(data: TClinicSettings) {
  return axios({
    method: 'put',
    data,
    url: `${process.env.REACT_APP_AUTH_URL}/api/v2/clinics/${getSelectedIdClinic()}/settings`,
  });
}

/* END CRM */
const exported = {
  getBalance,
  getBalanceLogs,
  list,
  select,
  getHakDanKewajibanPasien,
  update,
  getKioskPage,
  getLandingPage,
  activateLandingPage,
  updateLandingPage,
  getLandingPagePost,
  createLandingPagePost,
  updateLandingPagePost,
  deleteLandingPagePost,
  getSatuSehat,
  updateSatuSehat,
  getCrm,
  activateCrm,
  updateCrm,

  getSettings,
  updateSettings,
};

export default exported;
