import axios from 'lib/fetch/axios';

function login(data: { password: string; email: string }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_AUTH_URL2}/auth/web-app/login/email`,
    data,
  });
}

function rootLogin(data: { token: string }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_AUTH_URL2}/auth/web-app/login/root`,
    data,
  });
}

function refresh(data: { refreshToken: string }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_AUTH_URL2}/auth/web-app/refresh-token`,
    data,
  });
}

const exported = {
  login,
  refresh,
  rootLogin,
};

export default exported;
