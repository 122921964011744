import { ArrowSquareOut } from '@phosphor-icons/react';
import modal from 'react-components/dist/components/Modal';
import Button from 'react-components/dist/components/Button';
import ModalLauncher from './ModalLauncher';

function Launcher() {
  function modalLauncher() {
    modal.show(<ModalLauncher />, {
      close: {
        outsideClick: true,
        closeOnEscape: true,
        display: false,
      },
      wrapper: {
        overrideClassName:
          'bg-white dark:bg-gray-900 w-[786px] relative bg-white shadow-lg rounded',
      },
    });
  }

  return (
    <div className="group relative mt-1">
      <Button
        ripple
        className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900 "
        state="custom"
      >
        <button onClick={modalLauncher}>
          <ArrowSquareOut size={22} />
        </button>
      </Button>
      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
        Launcher (Buka Jendela Pemanggil Antrian)
      </span>
    </div>
  );
}

export default Launcher;
