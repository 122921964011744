import { ClockCounterClockwise, SealCheck, ShoppingCartSimple } from '@phosphor-icons/react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { forceHideSidebar, toggleSidebar } from 'lib/store/slices/components';
import Button from 'react-components/dist/components/Button';
import { Link, useLocation } from 'react-router-dom';

function ButtonPos() {
  const users = useAppSelector((state) => state.users);
  const { selected } = useAppSelector((state) => state.clinics);
  const services = useAppSelector((state) => state.services);
  const dispatch = useAppDispatch();
  const location = useLocation();

  if (!selected) return null;

  if (
    users.profile?.entity !== 'owner' &&
    (!users.profile?.authority?.pos?.create || !users.profile?.authority?.pos?.logs)
  )
    return null;

  return (
    <div
      className={[
        '-mx-1 flex flex-col gap-y-2 rounded-full p-1 transition-all duration-300',
        location.pathname.includes('/pos')
          ? 'bg-blue-700 dark:bg-gray-800'
          : 'group hover:bg-blue-700 hover:dark:bg-gray-800',
      ].join(' ')}
    >
      {(users.profile?.entity === 'owner' || users.profile?.authority?.pos?.create) && (
        <div className="group relative">
          <Button
            ripple
            className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
            state="custom"
          >
            <Link
              to={`/clinics/${selected._id}/pos`}
              onClick={() => dispatch(toggleSidebar(false))}
            >
              <ShoppingCartSimple size={22} />
            </Link>
          </Button>
          {!location.pathname.includes('/pos') && services.states['waiting'] > 0 && (
            <span
              style={{ right: 2, bottom: 2 }}
              className="pointer-events-none absolute flex h-2 w-2 items-center justify-center rounded-full border
border-white bg-yellow-700 text-xs font-bold text-blue-900"
            ></span>
          )}
          {location.pathname.includes('/pos') &&
            !(location.pathname.includes('/logs') || location.pathname.includes('/waiting')) && (
              <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
            )}
          <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
            Kasir
          </span>
        </div>
      )}

      <div
        className={[
          'group relative transition-all duration-300',
          (users.profile?.entity === 'owner' || users.profile?.authority?.pos?.create) &&
          location.pathname.includes('/pos')
            ? ''
            : 'hidden opacity-0 group-hover:block group-hover:opacity-100',
        ].join(' ')}
      >
        <Button
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
          state="custom"
        >
          <Link
            to={`/clinics/${selected._id}/pos/waiting`}
            onClick={() => {
              dispatch(forceHideSidebar(false));
              dispatch(toggleSidebar(false));
            }}
          >
            <SealCheck size={22} />
          </Link>
        </Button>
        {services.states['waiting'] > 0 && (
          <span className="pointer-events-none absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full border border-white bg-yellow-700 text-xxs font-bold text-blue-900">
            {services.states['waiting']}
          </span>
        )}
        {location.pathname.includes('/waiting') && (
          <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
        )}
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Menunggu Pembayaran
        </span>
      </div>

      <div
        className={[
          'group relative transition-all duration-300',
          (users.profile?.entity === 'owner' || users.profile?.authority?.pos?.create) &&
          location.pathname.includes('/pos')
            ? ''
            : 'hidden opacity-0 group-hover:block group-hover:opacity-100',
        ].join(' ')}
      >
        <Button
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
          state="custom"
        >
          <Link
            to={`/clinics/${selected._id}/pos/logs`}
            onClick={() => {
              dispatch(forceHideSidebar(false));
              dispatch(toggleSidebar(false));
            }}
          >
            <ClockCounterClockwise size={22} />
          </Link>
        </Button>
        {location.pathname.includes('/logs') && (
          <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
        )}
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Riwayat Transaksi
        </span>
      </div>
    </div>
  );
}

export default ButtonPos;
