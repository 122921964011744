import { createSlice, current } from '@reduxjs/toolkit';

type Sidebar = {
  isActive: boolean;
  isForceHidden: boolean;
  isModuleIntegrationsActive: boolean;
};

type colorScheme = 'dark' | 'light' | 'system-default';

type App = {
  isReady: boolean;
  colorScheme: colorScheme;
};

// type TForms = "form-rekam-medis" | "form-patients";

type Forms = {
  [key: string]: any;
};

export interface IComponentsState {
  sidebar: Sidebar;
  app: App;
  forms: Forms;
  speech: any;
  addOn: {
    [key: string]: any;
  };
}

const initialState: IComponentsState = {
  sidebar: {
    isActive: false,
    isModuleIntegrationsActive: false,
    isForceHidden: false,
  },
  app: {
    isReady: false,
    colorScheme: 'light',
  },
  forms: {},
  addOn: {
    services: {},
  },
  speech: null,
};

export const componentsSlicer = createSlice({
  name: 'components',
  initialState,
  reducers: {
    changeColorScheme: (
      state: IComponentsState,
      action: {
        type: string;
        payload: colorScheme;
      }
    ) => {
      state.app.colorScheme = action.payload;
    },
    setSpeechUtterance: (
      state: IComponentsState,
      action: {
        type: string;
        payload: any;
      }
    ) => {
      state.speech = action.payload;
    },
    toggleApp: (
      state: IComponentsState,
      action: {
        type: string;
        payload: boolean | undefined;
      }
    ) => {
      state.app.isReady =
        typeof action.payload === 'boolean' ? !!action?.payload : !state.app.isReady;
    },
    toggleSidebar: (
      state: IComponentsState,
      action: {
        type: string;
        payload: boolean | undefined;
      }
    ) => {
      state.sidebar.isActive =
        typeof action.payload === 'boolean' ? !!action?.payload : !state.sidebar.isActive;
    },
    toggleSidebarModuleIntegrations: (
      state: IComponentsState,
      action: {
        type: string;
        payload: boolean | undefined;
      }
    ) => {
      state.sidebar.isModuleIntegrationsActive =
        typeof action.payload === 'boolean'
          ? !!action?.payload
          : !state.sidebar.isModuleIntegrationsActive;
    },
    forceHideSidebar: (
      state: IComponentsState,
      action: {
        type: string;
        payload: boolean;
      }
    ) => {
      state.sidebar.isForceHidden = action.payload;
    },
    initForms: (
      state: IComponentsState,
      action: {
        payload: {
          key: string;
          value: any;
          uid: string;
        };
      }
    ) => {
      state.forms[action.payload.key] = {
        ...(state.forms[action.payload.key] || {}),
        [action.payload?.uid]: {
          lastUpdate: new Date(),
          uid: action.payload?.uid,
          status: 'ready',
          payload: {
            ...(state.forms?.[action.payload.key]?.[action.payload?.uid]?.payload || {}),
            ...action.payload.value,
          },
        },
      };
    },
    manageForms: (
      state: IComponentsState,
      action: {
        payload: {
          key: string;
          value: any;
          uid: string;
        };
      }
    ) => {
      const currentState = current(state);
      if (state.forms[action.payload.key]?.[action.payload?.uid]?.status === 'ready') {
        // state.forms[action.payload.key] = {
        //   ...(state.forms[action.payload.key] || {}),
        //   [action.payload?.uid]: {
        //     ...(state.forms[action.payload.key]?.[action.payload?.uid] || {}),
        //     lastUpdate: new Date(),
        //     payload: {
        //       ...(state.forms?.[action.payload.key][action.payload?.uid]
        //         ?.payload || {}),
        //       ...action.payload.value,
        //     },
        //   },
        // };
        state.forms[action.payload.key][action.payload?.uid] = {
          ...(currentState.forms[action.payload.key]?.[action.payload?.uid] || {}),
          lastUpdate: new Date(),
          payload: {
            ...(currentState.forms?.[action.payload.key][action.payload?.uid]?.payload || {}),
            ...action.payload.value,
          },
        };
      }
    },
    resetForms: (
      state: IComponentsState,
      action: {
        payload: {
          idForm: string;
          id?: string[];
        };
      }
    ) => {
      if (action.payload.id && action.payload.id.length > 0) {
        for (const iterator of action.payload.id) {
          state.forms[action.payload.idForm][iterator] = undefined;
        }
      }
    },
    resetKeyForms: (
      state: IComponentsState,
      action: {
        payload: {
          idForm: string;
        };
      }
    ) => {
      state.forms[action.payload.idForm] = {};
    },
    resetAllForms: (state: IComponentsState) => {
      state.forms = {};
    },
    addOnInit: (
      state: IComponentsState,
      action: {
        payload: {
          key: string;
          init: any;
        };
      }
    ) => {
      if (!Object.hasOwn(state, 'addOn')) {
        state.addOn = {};
      }
      state.addOn[action.payload.key] = action.payload.init;
    },
    addOnSet: (
      state: IComponentsState,
      action: {
        payload: {
          key: string;
          spread: any;
        };
      }
    ) => {
      if (!Object.hasOwn(state, 'addOn')) {
        state.addOn = {};
      }
      state.addOn[action.payload.key] = {
        ...(state.addOn?.[action.payload.key] || {}),
        ...action.payload.spread,
      };
    },
  },
});

export const {
  toggleApp,
  toggleSidebar,
  forceHideSidebar,
  initForms,
  manageForms,
  resetForms,
  resetKeyForms,
  resetAllForms,
  changeColorScheme,
  setSpeechUtterance,
  addOnInit,
  addOnSet,
  toggleSidebarModuleIntegrations,
} = componentsSlicer.actions;

export default componentsSlicer.reducer;
