import { useCallback, useState } from 'react';
import Button from 'react-components/dist/components/Button';
import Form from 'react-components/dist/components/Form';
import modal from 'react-components/dist/components/Modal';

import { toast } from 'react-toastify';

type Props = {
  title: String;
  description: String;
  onNext: {
    label: string;
    exec: Function;
    message: string;
  };
  onFailed?: {
    message?: string;
  };
  onCancel?: {
    label: string;
    exec?: Function;
  };
  isHighalert?: Boolean;
  body?: React.ReactNode;
};

function ModalConfirm({
  onNext,
  onCancel,
  onFailed,
  title,
  description,
  isHighalert,
  body,
}: Props) {
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const onConfirm = useCallback(
    async (payload: any) => {
      setDeleteLoading(true);
      try {
        await onNext.exec(payload);

        toast.success(onNext.message);

        setDeleteLoading(false);
        modal.hide();
      } catch (error: any) {
        toast.warning(
          onFailed?.message
            ? onFailed?.message
            : error?.isAxiosError
            ? error?.response?.data?.message
            : error?.message
        );
        setDeleteLoading(false);
      }
    },
    [onNext, onFailed?.message]
  );

  return (
    <div className="flex flex-col px-8 py-2">
      <h6 className="text-center text-xl text-gray-600 dark:text-gray-400">{title}</h6>
      <p className="text-sm text-gray-900 dark:text-gray-200">{description}</p>
      <Form onSubmit={onConfirm}>
        {body}
        <div className="mt-8 flex justify-end gap-x-2">
          <Button ripple size="sm" className="px-2" state="tertiary" color="stale">
            <button type="button" onClick={() => (onCancel?.exec ? onCancel.exec() : modal.hide())}>
              {onCancel?.label || 'Batal'}
            </button>
          </Button>
          <Button
            isLoading={isDeleteLoading}
            ripple={!isHighalert}
            size="sm"
            className="px-2"
            state={isHighalert ? 'tertiary' : 'secondary'}
            color={isHighalert && 'danger'}
          >
            <button type="submit">{onNext.label}</button>
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ModalConfirm;
