import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import APIClinics from 'lib/constants/api/clinics';
import { selectClinic } from 'lib/store/slices/clinics';
import { login } from 'lib/store/slices/users';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const useSettings = () => {
  const dispatch = useAppDispatch();
  const clinics = useAppSelector((state) => state.clinics);
  const toastId = useRef<any>(null);

  const [formLoading, setFormLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (payload: any, onSuccessMessage: string, onNext?: Function) => {
      if (clinics.selected?._id) {
        try {
          setFormLoading(true);

          payload._id = clinics.selected?._id;

          await APIClinics.update(payload);

          toastId.current = toast.info('Memperbarui informasi faskes...', {
            autoClose: false,
          });

          const response = await APIClinics.select(clinics.selected?._id);

          const updatedClinicsProfile = {
            ...response.data.clinicDetails,
            poli: response.data.poli,
            doctor: response.data.doctor,
            iks: response.data.iks,
            nurse: response.data.nurse,
            staff: response.data.staff,
          };

          if (Object.hasOwn(payload, 'settings')) {
            const settings = await APIClinics.updateSettings(payload.settings);
            updatedClinicsProfile.settings = settings.data.settings;
          }

          dispatch(selectClinic(updatedClinicsProfile));

          dispatch(
            login({
              token: response.data.user.token,
              tokenRefresh: response.data.user.tokenRefresh,
            })
          );

          toast.update(toastId.current, {
            render: onSuccessMessage,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
          });

          setFormLoading(false);

          onNext && onNext();
        } catch (error: any) {
          setFormLoading(false);

          toast.warning(JSON.stringify(error.response.data));
        }
      }
    },
    [clinics.selected?._id, dispatch]
  );

  return { formLoading, onSubmit };
};

export default useSettings;
