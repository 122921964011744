function decodeJWT(token: string) {
  try {
    // Split the token into its three parts
    const [, payloadEncoded] = token.split('.');

    // Decode the base64 URL-safe encoded payload
    const payloadDecoded = atob(payloadEncoded.replace(/-/g, '+').replace(/_/g, '/'));

    // Parse the decoded payload into a JavaScript object
    const payload = JSON.parse(payloadDecoded);

    return payload;
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    return null;
  }
}

export default decodeJWT;
