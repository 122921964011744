import React, { ReactNode, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { useAppDispatch } from 'lib/store/hooks';
import { toggleSidebar } from 'lib/store/slices/components';
import useQueryParams, { getQueryParams } from 'lib/helpers/useQueryParams';
// import NavLink from "components/atoms/NavLink";

// import { useAppSelector } from "lib/store/hooks";

export type TMenu = {
  title: ReactNode /* label show after icon */;
  slug?: string /* hyperlink */;
  hasAccess?: Boolean /* conditions to hide/show the menu node */;
  icon?: ReactNode /* Icon usually support by phosphoricons */;
  iconActive?: ReactNode /* Icon usually support by phosphoricons */;
  child?: {
    [key: string]: TMenu;
  } /* another menu of child */;
  searchQueryKey?: string;
  badge?: ReactNode /* badge to catch attention */;
  isUnderconstruction?: Boolean /* disabled on production when underconstruction */;
  hasPulse?: Boolean /* has indicator to catch the attention */;
  isAnchor?: Boolean /* is collapsible instead of hyperlink */;
  end?: Boolean /* menu is end of the node */;
  onClick?: any /* onClick function accept (event) => functions */;
  isMarquee?: Boolean /* isMarquee add manually if text too long */;
};

interface IMenu {
  [key: string]: TMenu;
}

interface Props {
  menus: IMenu;
  className?: string;
}

// interface ICurrent {}

/**
  accept object as menus to display unique items
 */

const Decoration = ({ item, active }: { item: TMenu; active?: Boolean }) => {
  return (
    <>
      {active ? item.iconActive || item.icon : item.icon}
      <span
        data-uid-testing={`menu-${item.title}`}
        className={[
          'pl-2',
          item.isMarquee ? 'hover:overflow-visible hover:animate-marquee' : '',
        ].join(' ')}
      >
        {item.title}
      </span>
      {item.hasPulse && (
        <div className="absolute right-0 top-0 flex h-full w-10 items-center justify-center">
          <div className="relative h-3 w-3">
            <span
              className={[
                'absolute inline-flex h-full w-full animate-ping rounded-full bg-yellow-400 opacity-75',
              ].join(' ')}
            ></span>
            <span className="relative flex h-3 w-3 rounded-full bg-yellow-500"></span>
          </div>
        </div>
      )}
      {!!item.badge && (
        <div className="absolute right-0 top-0 flex h-full w-10 items-center justify-center">
          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-200 text-[11px] font-bold text-gray-800">
            {item.badge}
          </div>
        </div>
      )}
    </>
  );
};

const ListMenu = ({ menus, className = '' }: Props) => {
  const [active, setActive] = useState<string | null>(null);
  // const clinics = useAppSelector((state) => state.clinics);
  const dispatch = useAppDispatch();
  const queryParams = useQueryParams();
  // const location = useLocation();

  function fnToggle(event: React.MouseEvent, collection: TMenu, item: string) {
    if (!collection.isAnchor) {
      // setActive(null);
      dispatch(toggleSidebar(false));
      return;
    }
    event.preventDefault();

    setActive((prev) => (item === prev ? null : item));
  }

  function handleClick(props: TMenu) {
    if (props.onClick) props.onClick();
  }

  const renderItems = (current: IMenu) => {
    return Object.keys(current).map((item: string) => {
      if (typeof current[item].hasAccess === 'boolean' && !current[item].hasAccess) return null;

      return (
        <li key={item} className="mb-2 flex flex-col">
          <NavLink
            onClick={(event: React.MouseEvent) => {
              if (!!current[item].isUnderconstruction) {
                event.preventDefault();
                return;
              }
              handleClick(current[item]);

              fnToggle && fnToggle(event, current[item], item);
            }}
            to={`${current[item].slug}`}
            className={({ isActive }) => {
              let classNames = 'text-gray-800 dark:text-gray-200';

              if (current?.[item]?.slug?.includes('?') && !!current?.[item]?.searchQueryKey) {
                const [, search] = (current[item]?.slug || '')?.split('?');
                const queries = getQueryParams(search);

                const isQueryParamsActive =
                  queries[current?.[item]?.searchQueryKey as string] ===
                  queryParams[current?.[item]?.searchQueryKey as string];

                if (isQueryParamsActive)
                  classNames =
                    'active bg-gray-100 text-blue-800 dark:bg-gray-800 dark:text-yellow-700';
              } else if (active === item || isActive) {
                classNames =
                  'active bg-gray-100 text-blue-800 dark:bg-gray-800 dark:text-yellow-700';
              }

              if (!!current[item].isUnderconstruction)
                classNames = 'cursor-not-allowed text-gray-300 hover:bg-gray-50';

              return [
                'menu relative flex items-center rounded px-3 py-2 text-sm font-medium transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-700',
                classNames,
                current[item].isMarquee ? 'overflow-hidden' : '',
              ].join(' ');
            }}
            end={!!current[item].end}
          >
            {({ isActive }) => {
              return <Decoration item={current[item]} active={isActive || active === item} />;
            }}
          </NavLink>

          {current[item].child instanceof Object && !!current[item].child && (
            <ul className="mt-2 flex w-full flex-col">
              <ListMenu menus={current[item]?.child || {}} />
              {/* {renderItems(current[item]?.child || {})} */}
            </ul>
          )}
        </li>
      );
    });
  };

  return <ul className={['flex w-full flex-col', className].join(' ')}>{renderItems(menus)}</ul>;
};

export default ListMenu;
