import { CalendarBlank } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { toggleSidebar } from 'lib/store/slices/components';
import Button from 'react-components/dist/components/Button';
import { Link, useLocation } from 'react-router-dom';
import { id as ID } from 'date-fns/locale';

function ButtonCalendar() {
  const calendars = useAppSelector((state) => state.calendars);
  const users = useAppSelector((state) => state.users);
  const { selected } = useAppSelector((state) => state.clinics);
  const dispatch = useAppDispatch();
  const location = useLocation();

  if (users.profile?.entity !== 'owner' && !users.profile?.authority?.appointment) return null;

  return (
    <div className="group relative">
      <Button
        ripple
        className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
        state="custom"
      >
        <Link
          to={`/clinics/${selected?._id}/calendars`}
          onClick={() => dispatch(toggleSidebar(false))}
        >
          <span className="relative">
            <CalendarBlank size={22} />
            <span className="absolute inset-0 flex w-full items-center justify-center pt-1 text-[8px] font-bold">
              {new Date().getDate()}
            </span>
            <span className="absolute -bottom-[12px] flex w-full items-center justify-center pt-[2px] text-[7px] font-bold tracking-wider uppercase">
              {format(new Date(), 'MMM', { locale: ID })}
            </span>
          </span>
        </Link>
      </Button>
      {/* {!location.pathname.includes("/calendars") &&
            calendars.count > 0 && (
              <span
                style={{ right: 2, bottom: 2 }}
                className="pointer-events-none absolute flex h-2 w-2 items-center justify-center rounded-full border
border-white bg-yellow-700 text-xs font-bold text-blue-900"
              ></span>
            )} */}
      {calendars.count > 0 && (
        <span className="pointer-events-none absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full border border-white bg-yellow-700 text-xxs font-bold text-blue-900">
          {calendars.count}
        </span>
      )}
      {location.pathname.includes('/calendars') &&
        !(location.pathname.includes('/logs') || location.pathname.includes('/waiting')) && (
          <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
        )}
      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
        Kalender
      </span>
    </div>
  );
}

export default ButtonCalendar;
