import React from 'react';

import 'assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import store from 'lib/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { ModalProvider } from 'react-components/dist/components/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';

import packageJson from '../package.json';
// import { FlagProvider, IConfig } from "@unleash/proxy-client-react";

import routerWindow from 'components/atoms/CustomPrompt/routerWindow';

// const unleashConfig: IConfig = {
//   url: process.env.REACT_APP_UNLEASH_URL || "",
//   clientKey: process.env.REACT_APP_UNLEASH_KEY || "",
//   refreshInterval: 60,
//   appName: "app2.mejadokter.com",
//   environment: "production",
// };

if (process.env.REACT_APP_STAGING === 'PRODUCTION') {
  Sentry.init({
    release: `${packageJson.name}@${packageJson.version}`,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: 'production',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost:8080',
          'https:api.mejadokter.com',
          'https:api-staging.mejadokter.com',
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      'Router navigation was blocked',
      'NotAllowedError: The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    ],
  });
}

const persistor = persistStore(store);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter window={routerWindow}>
          <App />

          <ToastContainer
            bodyClassName="dark:bg-gray-800 dark:text-gray-200"
            toastClassName="dark:bg-gray-800"
            position="bottom-left"
            newestOnTop
            pauseOnFocusLoss
            draggable
          />

          <ModalProvider
            options={{
              close: {
                outsideClick: true,
                closeOnEscape: true,
                display: true,
                className:
                  'absolute right-0 top-0 w-8 h-8 flex items-center dark:text-gray-400 justify-center text-2xl',
              },
              overlay: {
                timeout: 300,
                className:
                  'transition-all duration-300 fixed inset-0 dark:bg-white/0 bg-black/0 z-50 overflow-y-auto',
                style: {},
                animatedClassNames: {
                  appear: 'bg-black/0 dark:bg-white/0',
                  appearActive: 'bg-black/0 dark:bg-white/0',
                  appearDone: 'bg-black/20 dark:bg-white/20',
                  enter: 'bg-black/0 dark:bg-white/0',
                  enterActive: 'bg-black/0 dark:bg-white/0',
                  enterDone: 'bg-black/20 dark:bg-white/20',
                  exit: '',
                  exitActive: 'bg-black/0 dark:bg-white/0',
                  exitDone: 'bg-black/0 dark:bg-white/0',
                },
              },
              container: {
                className: 'flex items-center justify-center min-h-screen',
                style: {},
              },
              wrapper: {
                timeout: 300,
                className:
                  'transition-all duration-300 bg-white dark:bg-gray-900 border border-transparent dark:border-gray-700 relative shadow-great opacity-0',
                style: {},
                animatedClassNames: {
                  appear: 'opacity-0 -top-5',
                  appearActive: 'opacity-0 -top-5',
                  appearDone: 'opacity-100 top-0',
                  enter: 'opacity-0 -top-5',
                  enterActive: 'opacity-0 -top-5',
                  enterDone: 'opacity-100 top-0',
                  exit: 'top-0',
                  exitActive: 'opacity-0 -top-5',
                  exitDone: 'opacity-0 -top-5',
                },
              },
            }}
          />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
