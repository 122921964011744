import { ReactElement, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';

import ListMenu, { TMenu } from 'components/molecules/ListMenu';
import { selectClinic, setAvailableClinic } from 'lib/store/slices/clinics';

import { Clinic } from 'lib/store/slices/clinics';
import { default as APIClinics } from 'lib/constants/api/clinics';

import modal from 'react-components/dist/components/Modal';
import Pill from 'react-components/dist/components/Pill';
import { changeColorScheme, toggleSidebar } from 'lib/store/slices/components';
import useLogout from 'lib/helpers/useLogout';

import { ArrowSquareOut, LockKey, PencilSimple, SignOut } from '@phosphor-icons/react';
import { login } from 'lib/store/slices/users';
import Input from 'react-components/dist/components/Input';
import { Link } from 'react-router-dom';
import APIUsers from 'lib/constants/api/users';

type Props = {
  isLoadingSelectingClinicSet: Function;
};

const ModalProfile = ({ isLoadingSelectingClinicSet }: Props): ReactElement => {
  const users = useAppSelector((state) => state.users);
  const clinics = useAppSelector((state) => state.clinics);
  const components = useAppSelector((state) => state.components);
  const dispatch = useAppDispatch();

  const fnLogout = useLogout();

  async function fnSelectClinic(item: { [key: string]: string }) {
    try {
      modal.hide();
      isLoadingSelectingClinicSet(true);
      const response = await APIClinics.select(item._id);
      const settings = await APIClinics.getSettings(item._id);

      isLoadingSelectingClinicSet(false);

      dispatch(toggleSidebar(false));

      dispatch(
        selectClinic({
          ...response.data.clinicDetails,
          poli: response.data.poli,
          iks: response.data.iks,
          staff: response.data.staff,
          doctor: response.data.doctor,
          nurse: response.data.nurse,
          settings: settings.data?.settings || {},
        })
      );

      const loginPayload: any = {
        token: response.data.user.token,
        tokenRefresh: response.data.user.tokenRefresh,
        profile: { ...users.profile },
      };

      if (users?.profile?.entity !== 'owner') {
        const resAuthority = await APIUsers.getAuthorityByUserIdAndClinicId(
          users?.profile?.idUser,
          item._id
        );
        loginPayload.profile.authority = resAuthority.data.authority;
      }

      dispatch(login(loginPayload));
    } catch (error) {
      isLoadingSelectingClinicSet(false);
    }
  }

  const getListClinics = useCallback(async () => {
    try {
      const clinics = await APIClinics.list();
      dispatch(setAvailableClinic(clinics?.data?.data ?? []));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getListClinics();
  }, [getListClinics]);

  const menus: any = {
    'ubah-profil': {
      title: 'Perbarui Profil',
      icon: (
        <span className="text-gray-800 dark:text-gray-200">
          <PencilSimple size={20} />
        </span>
      ),
      slug: '/ubah-profil',
      onClick: () => {
        dispatch(toggleSidebar(false));
        modal.hide();
      },
    },
    'ubah-password': {
      title: 'Ubah Password',
      icon: (
        <span className="text-gray-800 dark:text-gray-200">
          <LockKey size={20} />
        </span>
      ),
      slug: '/ubah-password',
      onClick: () => {
        dispatch(toggleSidebar(false));
        modal.hide();
      },
    },
    logout: {
      title: 'Keluar',
      icon: (
        <span className="text-gray-800 dark:text-gray-200">
          <SignOut size={20} />
        </span>
      ),
      slug: '/login',
      onClick: fnLogout,
    },
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="mt-4 flex h-12 w-full items-center justify-start px-4">
          {/* <div className="flex-none h-10 w-10 mr-3 overflow-hidden rounded-md">
          <img
            className="object-contain"
            src={users.profile?.image}
            alt={users.profile?.name}
          />
        </div> */}
          <div className="w-3/4">
            <h1 className="truncate text-sm font-medium text-gray-700 dark:text-gray-200">
              {users.profile?.fullName}
            </h1>
            <Pill state="info" size="sm">
              {users.profile?.entity}
            </Pill>
          </div>
        </div>

        <div className="flex justify-between items-center mb-2 mt-4 px-4 text-gray-800 dark:text-gray-200">
          <h2 className="text-sm">Kelola Faskes</h2>
          <Link
            to="/clinics"
            onClick={() => {
              modal.hide();
            }}
          >
            <ArrowSquareOut size={22} />
          </Link>
        </div>
        <ListMenu
          menus={clinics.available.reduce((acc: { [key: string]: TMenu }, item: Clinic) => {
            const branch = [];
            if (!!item.branch && item.branch !== '') branch.push(`Branch ${item.branch}`);
            if (!!item.kelurahan && item.kelurahan !== '') branch.push(item.kelurahan);
            if (!!item.kecamatan && item.kecamatan !== '') branch.push(item.kecamatan);
            if (!!item.kabupaten && item.kabupaten !== '') branch.push(item.kabupaten);
            if (!!item.provinsi && item.provinsi !== '') branch.push(item.provinsi);
            acc[item._id] = {
              title: (
                <span className="flex flex-col">
                  <span>{item.name}</span>
                  <span className="text-xs">{branch.join(', ')}</span>
                </span>
              ),
              slug: `/clinics/${item._id}`,
              onClick: () => fnSelectClinic(item),
            };
            return acc;
          }, {})}
        />

        <h2 className="mb-2 mt-4 px-4 text-sm text-gray-800 dark:text-gray-200">Sistem</h2>
        <ul className="flex w-full flex-col px-4">
          <li className="mb-2 flex flex-col">
            <Input
              id="color-scheme"
              type="radio"
              name="color-scheme"
              label="Mode Warna"
              wrapperClassName="mb-4"
              listWrapperClassName="flex flex-col gap-y-2"
              onChange={(event: any) => {
                dispatch(changeColorScheme(event.target.value));
              }}
              list={[
                {
                  value: 'dark',
                  label: 'Malam',
                },
                {
                  value: 'light',
                  label: 'Terang',
                },
                {
                  value: 'system-default',
                  label: 'Bawaan sistem',
                },
              ]}
              defaultValue={components.app.colorScheme}
            />
          </li>
        </ul>

        <h2 className="mb-2 mt-4 px-4 text-sm text-gray-800 dark:text-gray-200">Profil</h2>
        <ListMenu menus={menus} className="px-4" />
      </div>
    </>
  );
};

export default ModalProfile;
