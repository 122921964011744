import {
  ChatTeardropText,
  ClockCounterClockwise,
  PaperPlaneTilt,
  Plug,
} from '@phosphor-icons/react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { forceHideSidebar, toggleSidebar } from 'lib/store/slices/components';
import Button from 'react-components/dist/components/Button';
import { Link, useLocation } from 'react-router-dom';

function ButtonCrm() {
  const { selected } = useAppSelector((state) => state.clinics);
  const dispatch = useAppDispatch();
  const location = useLocation();

  if (!selected || !location.pathname.includes('/integrations/crm')) return null;

  return (
    <div
      className={[
        '-mx-1 flex flex-col gap-y-2 rounded-full p-1 transition-all duration-300',
        location.pathname.includes('/integrations/crm')
          ? 'bg-blue-700 dark:bg-gray-800'
          : 'group hover:bg-blue-700 hover:dark:bg-gray-800',
      ].join(' ')}
    >
      <div className="group relative mb-2">
        <Button
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
          state="custom"
        >
          <Link to={`/clinics/${selected._id}/integrations/crm/overview`}>
            <Plug size={22} />
          </Link>
        </Button>
        <span className="pointer-events-none z-20 border-2 -bottom-2 left-1/2 -translate-x-1/2 absolute flex h-4 items-center justify-center rounded-full bg-white dark:bg-gray-950 text-gray-900 dark:text-gray-200 text-[8px] font-black px-1">
          CRM
        </span>
        {location.pathname.includes('/integrations/crm') &&
          !['/logs', '/marketing-campaign', 'communication'].some((path) =>
            location.pathname.includes(path)
          ) && (
            <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
          )}
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Ringkasan | Hubungan Pelanggan {'[CRM]'}
        </span>
      </div>

      <div
        className={[
          'group relative transition-all duration-300',
          location.pathname.includes('/integrations/crm')
            ? ''
            : 'hidden opacity-0 group-hover:block group-hover:opacity-100',
        ].join(' ')}
      >
        <Button
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
          state="custom"
        >
          <Link
            to={`/clinics/${selected._id}/integrations/crm/logs`}
            onClick={() => {
              dispatch(forceHideSidebar(false));
              dispatch(toggleSidebar(false));
            }}
          >
            <ClockCounterClockwise size={22} />
          </Link>
        </Button>
        {location.pathname.includes('/integrations/crm/logs') && (
          <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
        )}
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Riwayat Pengiriman Pesan
        </span>
      </div>

      <div
        className={[
          'group relative transition-all duration-300',
          location.pathname.includes('/integrations/crm')
            ? ''
            : 'hidden opacity-0 group-hover:block group-hover:opacity-100',
        ].join(' ')}
      >
        <Button
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
          state="custom"
        >
          <Link
            to={`/clinics/${selected._id}/integrations/crm/marketing-campaign`}
            onClick={() => {
              dispatch(forceHideSidebar(false));
              dispatch(toggleSidebar(false));
            }}
          >
            <PaperPlaneTilt size={22} />
          </Link>
        </Button>
        {location.pathname.includes('/integrations/crm/marketing-campaign') && (
          <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
        )}
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Pemasaran atau Kirim Pesan Masal
        </span>
      </div>

      <div
        className={[
          'group relative transition-all duration-300',
          location.pathname.includes('/integrations/crm')
            ? ''
            : 'hidden opacity-0 group-hover:block group-hover:opacity-100',
        ].join(' ')}
      >
        <Button
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
          state="custom"
        >
          <Link
            to={`/clinics/${selected._id}/integrations/crm/communication`}
            onClick={() => {
              dispatch(forceHideSidebar(false));
              dispatch(toggleSidebar(false));
            }}
          >
            <ChatTeardropText size={22} />
          </Link>
        </Button>
        {location.pathname.includes('/integrations/crm/communication') && (
          <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
        )}
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Kelola Template Komunikasi
        </span>
      </div>
    </div>
  );
}

export default ButtonCrm;
